import getAuthorName from "../../../helpers/getAuthorName";
/**
 * Used in commonResourceTemplate to extract the correct markup based on the resource type
 * See docs: https://developers.google.com/search/docs/guides/search-gallery
 * @param {Gatsby pageContext object} pageContext
 */
const getResourceSchemaMarkup = (pageContext, siteUrl = 'https://www.accruent.com', schemaMarkupObj) => {
  const authorName = getAuthorName(pageContext?.relationships);
  const resourceType = pageContext.contentTypeId[0].id;
  // If schema is provided by publishers, use that
  const publishedSchemaMarkup = schemaMarkupObj
  if (publishedSchemaMarkup) {
    try {
      return JSON.parse(publishedSchemaMarkup)
    } catch(err) {
      console.log(err)
    }
  }else{
    return null
  }
  let _type;
  switch (resourceType) {
    case "blog_entry":
      _type = "BlogPosting";
      break;
    case "press_release":
      _type = "NewsArticle";
      break;
    case "video":
      _type = "VideoObject";
      break;
    default:
      _type = "Article";
  }
  if (_type === "VideoObject") {
    const thumbnailUrl = pageContext.fieldVideoRef?.src;
    return {
      "@context": "https://schema.org",
      "@type": _type,
      name: pageContext.title,
      description: pageContext.fieldVideoSubtitle || pageContext.title,
      uploadDate:pageContext.created && new Date(pageContext.created).toISOString(),
      thumbnailUrl: thumbnailUrl ? siteUrl + thumbnailUrl : undefined,
    };
  }
  const imageUrl = pageContext.heroImage?.src;
  const productUrl = pageContext.product?.[0]?.entityUrl?.path;
  return {
    "@context": "https://schema.org",
    "@type": _type,
    author: authorName || "Accruent",
    headline: pageContext.title,
    image: imageUrl ? siteUrl + imageUrl : undefined,
    datePublished: pageContext.created && new Date(pageContext.created)?.toISOString(),
    mainEntityOfPage: productUrl ? siteUrl + productUrl : undefined,
    publisher: {
      "@type": "Organization",
      name: "Accruent"
    },
    dateModified: pageContext.entityChanged && new Date(pageContext.entityChanged).toISOString(),
  }
};

export default getResourceSchemaMarkup;
