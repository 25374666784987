/**
 * @param {Array} metaTags
 */
const getMetaData = (metaTags) => {
  const entityMetatags = [];
  if (metaTags) {
    metaTags?.forEach(tag => {
      entityMetatags.push({
        name: tag?.attributes?.name,
        content: tag?.attributes?.content
      })
    })
  }
  return entityMetatags;
};

module.exports = getMetaData;
