import { useLayoutEffect } from "react";

const drupal_api_base = `${process.env.DRUPAL_API_BASE_URL ||
  "https://live-accruent.pantheonsite.io"}`;



const isUrlAbsolute = (url) => {
  if (url.indexOf("//") === 0) {
    return true;
  } // URL is protocol-relative (= absolute)
  if (url.indexOf("://") === -1) {
    return false;
  } // URL has no protocol (= relative)
  if (url.indexOf(".") === -1) {
    return false;
  } // URL does not contain a dot, i.e. no TLD (= relative, possibly REST)
  if (url.indexOf("/") === -1) {
    return false;
  } // URL does not contain a single slash (= relative)
  if (url.indexOf(":") > url.indexOf("/")) {
    return false;
  } // The first colon comes after the first slash (= relative)
  if (url.indexOf("://") < url.indexOf(".")) {
    return true;
  } // Protocol is defined before first dot (= absolute)
  return false; // Anything else must be relative
}

/**
 *
 * @param {Array or String} classes - array of classes that should be looked into so the images 
 * can have the src field edited into having the base URL into them.
 * 
 * When content editors put inline images into a Drupal node with ckeditor, they usually are missing the base
 * url, this finds them and prepends the drupal base url.
 */
const usePrependBaseURLIntoImages = (classes) => {
  let correctParameter = [];
  if (typeof classes === "string") {
    correctParameter.push(classes);
  } else {
    correctParameter = [...classes];
  }

  useLayoutEffect(() => {
    correctParameter.forEach((individualClass) => {
      // select the wrapper element that have the image element inside it
      const wrapperElements = document.querySelectorAll(`.${individualClass}`);
      if (wrapperElements && wrapperElements.length !== 0) {
        wrapperElements.forEach((wrapperElement)=>{
        // select all the images inside the wrapper element
        const imageElements = wrapperElement.querySelectorAll("img");
        if (imageElements && imageElements.length !== 0) {
          // for each image prepend the base URL into the src field
          imageElements.forEach((imageEl) => {
            if (typeof imageEl.attributes !== 'undefined' && imageEl.attributes.src) {
              if (!isUrlAbsolute(imageEl.attributes.src.value) && !(imageEl.attributes.class?.value === "locale-image")) {
                // determine if a path is relative or absolute
                const new_img_url = drupal_api_base + imageEl.attributes.src.value;
                imageEl.src = new_img_url;
              }
            }
          });
        }
        })
      }
    });
  }, [correctParameter]);
};

export default usePrependBaseURLIntoImages;
