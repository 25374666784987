import { get } from "src/utils";
import { getSrc } from "gatsby-plugin-image";
import findSiteType from "src/components/helpers/findSiteType";

const SocialShare = "accruent-social-share.png";
const MCSocialShare = "mc-social-share.png";
const EMSSocialShare = "ems-social-share.png";
const defaultImg = "/"+(findSiteType('mc')?MCSocialShare:findSiteType('ems')?EMSSocialShare:SocialShare)

const getOGMarkup =(title,type,heroImage) =>{
    const OGMarkup = [
        {
          property: "title",
          content: title,
        },
        {
          property: "type",
          content: type,
        },
      ];
      if (heroImage?.gatsbyImage) {
        OGMarkup.push({
          property: "image",
          localPath: getSrc(heroImage),
        });
        OGMarkup.push({
          property: "image:height",
          content: get(heroImage, "gatsbyImage.height"),
        });
        OGMarkup.push({
          property: "image:width",
          content: get(heroImage, "gatsbyImage.width"),
        });
      } else {
        OGMarkup.push({
          property: "image",
          localPath: defaultImg,
        });
        OGMarkup.push({
          property: "image:height",
          content: 378,
        });
        OGMarkup.push({
          property: "image:width",
          content: 720,
        });
      }
 return OGMarkup;
    }

export default getOGMarkup;