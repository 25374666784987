import React from "react";
import { graphql } from "gatsby";
import createRelatedResources from "src/components/helpers/createRelatedResources";
import usePrependBaseURLIntoImages from "src/components/helpers/usePrependBaseURLIntoImages";
import getResourceSchemaMarkup from "src/components/helpers/getResourceSchemaMarkup";
import getYouMayAlsoLikeResources from "src/components/helpers/getYouMayAlsoLikeResources"
import getOGMarkup from "src/components/helpers/getOGMarkup";
import loadable from '@loadable/component'
import "src/scss/resources/base.scss";
import { SEO, CommonResourceHero } from "src/components/commonComponents";
import { getResourcesHeroData } from "src/utils";
import { getPageDataJsonPath } from "src/utils";
import { useLazyLoadOnScroll } from "src/hooks/useLazyLoadOnScroll";
const getMetaData = require("src/../helpers/getMetaData");
// const SingleBrochurePost = loadable(() => import('src/components/resources/SingleBrochurePost'))
const SingleBrochurePost = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SingleBrochurePost
});
// const SingleArticlePost = loadable(() => import('src/components/resources/SingleArticlePost'))
const SingleArticlePost = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SingleArticlePost
});
// const SingleWhitePaperPost = loadable(() => import('src/components/resources/SingleWhitePaperPost'))
const SingleWhitePaperPost = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SingleWhitePaperPost
});
// const SingleCaseStudyPost = loadable(() => import('src/components/resources/SingleCaseStudyPost'))
const SingleCaseStudyPost = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SingleCaseStudyPost
});
// const SingleInfographicPost = loadable(() => import('src/components/resources/SingleInfographicPost'))
const SingleInfographicPost = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SingleInfographicPost
});
// const SingleWebinarPost = loadable(() => import('src/components/resources/SingleWebinarPost'))
const SingleWebinarPost = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SingleWebinarPost
});
// const SinglePodcastPost = loadable(() => import('src/components/resources/SinglePodcastPost'))
const SinglePodcastPost = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SinglePodcastPost
});
// const SinglePressReleasePost = loadable(() => import('src/components/resources/SinglePressReleasePost'))
const SinglePressReleasePost = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SinglePressReleasePost
});
// const SingleEbookPost = loadable(() => import('src/components/resources/SingleEbookPost'))
const SingleEbookPost = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SingleEbookPost
});
// const SingleVideoPost = loadable(() => import('src/components/resources/SingleVideoPost'))
const SingleVideoPost = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SingleVideoPost
});
// const SingleKnowledgeHubPost = loadable(() => import('src/components/resources/SingleKnowledgeHubPost'))
const SingleKnowledgeHubPost = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SingleKnowledgeHubPost
});
// const SingleCompanyNews = loadable(() => import('src/components/resources/SingleCompanyNews'))
const SingleCompanyNews = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SingleCompanyNews
});
// const SingleBlogPost = loadable(() => import('../../components/resources/SingleBlogPost'))
const SingleBlogPost = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.SingleBlogPost
});
const RecentResources = loadable(() => import('src/components/commonComponents'), {
  resolveComponent: (components) => components.RecentResources
 });
 // const GetInTouch = loadable(() => import('src/components/resources/GetInTouch'))
const GetInTouch = loadable(() => import("src/components/templateComponents"), {
  resolveComponent: (components) => components.GetInTouch
});

const SingleResourcePost = (context) => {
  switch (context?.contentTypeId?.[0]?.id) {
    case "blog_entry":
      return <SingleBlogPost data={context} />
    case "brochure":
      return <SingleBrochurePost data={context} />
    case "article":
      return <SingleArticlePost data={context} />
    case "white_paper":
      return <SingleWhitePaperPost data={context} />
    case "case_study":
      if(context?.fieldOverrideItem)
        return <SingleVideoPost data={context} />
      else
        return <SingleCaseStudyPost data={context} />
    case "infographic":
      return <SingleInfographicPost data={context} />
    case "webinar":
      return <SingleWebinarPost data={context} />
    case "podcast_episode":
      return <SinglePodcastPost data={context} />
    case "press_release":
      return <SinglePressReleasePost data={context} />
    case "ebook":
      return <SingleEbookPost data={context} />
    case "video":
      return <SingleVideoPost data={context} />
    case "knowledge_hub_":
      return <SingleKnowledgeHubPost data={context} />
    case "company_news":
      return <SingleCompanyNews data={context} />
    default:
      return <></>
  }
};
const getInTouchPages = ["blog_entry"]
const youMayAlsoLike = ["webinar", 'ebook', 'white_paper']
const noPreload = ["video"]
const CommonResourcesTemplate = ({ pageContext, data, location, path }) => {
  const locale = pageContext.locale || "en";
  const shouldLoad = useLazyLoadOnScroll();
  let recommendedResources = []
  if (data) {
    recommendedResources = getYouMayAlsoLikeResources(data, pageContext?.entityId);
  }
  let rectifiedRecommended = []
  if (data) {
    rectifiedRecommended = createRelatedResources(
      recommendedResources,
      {
        hasDescription: true,
      }
    );
  }

  usePrependBaseURLIntoImages("resources-page");

  let heroImage = pageContext?.heroImage; 

  const isYouMayAlsoLike = youMayAlsoLike.includes(pageContext.type?.id)
  const showYouMayAlsoLike = (("webinar" !== pageContext.type?.id) || pageContext?.fieldShowYouMayAlsoLikeSec)
  const showRecentResourcesSection = pageContext?.fieldHideRecentResourcesSect ? false : true
  const siteUrl = data?.site?.siteMetadata?.siteUrl;
  const schemaMarkup = getResourceSchemaMarkup(pageContext, siteUrl, pageContext?.fieldSchemaMarkup)
  let schemaMarkupVideo = getResourceSchemaMarkup(pageContext, siteUrl, pageContext?.fieldVideoSchemaMarkup)
  const fieldMarketoFormNode =  ("company_news" === pageContext.type?.id)? pageContext?.relationships?.fieldMarketoFormNode: null

  /**
   * @Open_Graph_Markup
   * Some of the data inside the objects will be done inside SEO component
   */
  const OGMarkup = getOGMarkup(pageContext.title, pageContext.contentTypeId?.[0]?.id, { gatsbyImage: heroImage?.shareImage ? heroImage?.shareImage : heroImage?.gatsbyImage });

  const entityMetatags = getMetaData(pageContext?.metatag);

  let hreflangAlternatives = []

  pageContext.hreflangData?.forEach(hrefData => {
    hreflangAlternatives.push({
      href: hrefData.path,
      hreflang: hrefData.locale
    })
  });

  hreflangAlternatives?.find(trans => {
    if (trans.hreflang == "en") {
      hreflangAlternatives.push({
        href: trans.href,
        hreflang: "x-default"
      })
    } else if(hreflangAlternatives.length < 2) {
      if (trans.hreflang !== "en") {
        hreflangAlternatives.push({
          href: trans.href,
          hreflang: "x-default"
        })
      }
    }
  })
  
  const drupalResourceTypeId = pageContext?.contentTypeId[0].id
  let skipLazyLoad = pageContext?.content_type === "webinar" ? false : true;
  let heroData = skipLazyLoad ? getResourcesHeroData(pageContext) : ""
  const pageDataPath = getPageDataJsonPath(pageContext?.entityUrl?.path)

  switch(pageContext?.entityUrl?.path){
    case "/resources/webinars/best-practices-webinar-famis-360-space-survey":{
      entityMetatags.push(
        {name: 'description', content: 'This webinar will go through the various business use cases for utilizing FAMIS 360 Space Survey, helping you get the most out of the platform.'}
      )
      break;
    }
    case "/resources/webinars/meridian-cloud-20233-product-release-webinar":{
      entityMetatags.push(
        {name: 'description', content: 'This webinar will help get you started with the best-in-class EDMS Meridian Cloud 2023.3 release by providing you with an introduction to features like visualization and master documents.'}
      )
      break;
    }
    case "/resources/webinars/partner-webinar-discover-new-accruent-partner-network-apn-program":{
      entityMetatags.push(
        {name: 'description', content: 'This webinar will provide an overview of the new Accruent Partner Network (APN) program, which aims to help all Accruent partners grow their customer base, reach new markets, and improve customer retention.'}
      )
      break;
    }
    default: {
      break;
    }
  }

  const preloadImages = [
    !heroData?.heroVideoThumb ? undefined : { src: heroData?.heroVideoThumb },
  ];

const search = location.search;
hreflangAlternatives = search.length > 0 ? [] : hreflangAlternatives

  return (
    <div className="new-resources-page tw-new-resources-page">
      <SEO
        lang={locale}
        pageUrl={pageContext?.entityUrl?.path}
        meta={entityMetatags}
        schemaMarkup={schemaMarkup}
        schemaMarkupVideo={schemaMarkupVideo}
        OGMarkup={OGMarkup}
        alternatives={hreflangAlternatives}
        heroImage={noPreload?.includes(drupalResourceTypeId)?undefined:heroImage}
        preloads= {[pageDataPath]}
        preloadedImages={preloadImages}
        isPreloadImageOnMobile={isYouMayAlsoLike}
      />
      {skipLazyLoad && <CommonResourceHero data={heroData}/>}
      {SingleResourcePost({ ...pageContext, rectifiedRecommended: rectifiedRecommended })}
      <section className="resource-secondary-content content-visibility-auto-mobile">
        {(showYouMayAlsoLike && showRecentResourcesSection) && <RecentResources location={location} isYouMayAlsoLike={isYouMayAlsoLike} resourceData={rectifiedRecommended} heading={isYouMayAlsoLike ? "You May Also Like" : "Recent"} locale={pageContext?.locale} drupalResourceTypeId={drupalResourceTypeId} />}
        {(getInTouchPages.includes(pageContext.type?.id) || !!fieldMarketoFormNode) && shouldLoad ? (
          <GetInTouch data={pageContext.contentTypeId[0].label} fieldMarketoFormNode={fieldMarketoFormNode} />
        ) : null}

      </section>
    </div>
  );
};

export default CommonResourcesTemplate;

// Hardcoding locale to `en` to meet business needs (MW-2143)
// Removed input variable $locale: String
export const query = graphql`
query resourcesByIds($industryId: String,$solutionId: String,$productId: String, $locale: String) {
  allSitePageByIndustry:  allSitePage(
    limit: 3
    filter: {
      componentChunkName: {
        eq: "component---src-templates-common-resources-template-js"
      }
      context: {
        locale: { eq: $locale }
        industryIds: { elemMatch: { industryId: { eq: $industryId } } }
        fieldHidePage: {ne: true}
        content_type: { ne: "press_release" }
      }
    }
  ) {
    nodes {
      componentChunkName
      randomNumber
      ...resourceCards
    }
  }
  site {
    siteMetadata {
      siteUrl
    }
  }
  allSitePageBySolution: allSitePage(
    limit: 3
    filter: {
      componentChunkName: {
        eq: "component---src-templates-common-resources-template-js"
      }
      context: {
        locale: { eq: $locale }
        solutionIds: { elemMatch: { solutionId: { eq: $solutionId} } }
        fieldHidePage: {ne: true}
        content_type: { ne: "press_release" }
      }
    }
  ) {
    nodes {
      componentChunkName
      randomNumber
      ...resourceCards
    }
  }
  allSitePageByProduct: allSitePage(
    limit: 3
    filter: {
      componentChunkName: {
        eq: "component---src-templates-common-resources-template-js"
      }
      context: {
        locale: { eq: $locale }
        productIds: { elemMatch: { productId: { eq: $productId} } }
        fieldHidePage: {ne: true}
        content_type: { ne: "press_release" }
      }
    }
  ) {
    nodes {
      componentChunkName
      randomNumber
      ...resourceCards
    }
  }
}
`;