import { orderBy } from "src/utils";
const getYouMayAlsoLikeResources = (data,currentEntityId)=>{
 if(!data){
     return []
 }
  let tmp = {}
  let   youMayAlsoLikeData = [...data.allSitePageByIndustry.nodes,...data.allSitePageBySolution.nodes,...data.allSitePageByProduct.nodes];
   youMayAlsoLikeData.forEach(res=>{
     tmp[res?.context?.entityId]=res
   })
   delete tmp[currentEntityId]
   youMayAlsoLikeData= Object.values(tmp) 
   youMayAlsoLikeData = orderBy(youMayAlsoLikeData, ['randomNumber'],['asc']); 
  return youMayAlsoLikeData.slice(0,3)
}

export default getYouMayAlsoLikeResources